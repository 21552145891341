import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../Axios';
import PrivatePageLayout from '../../layouts/PrivatePageLayout';

const SingleQuestion = () => {
  const { id, category } = useParams(); // Extract id and category from the URL
  const navigate = useNavigate(); // For navigation after editing
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false); // Editing state
  const [editData, setEditData] = useState({}); // Form data state for editing
  const [file, setFile] = useState(null); // State for new file

  const fetchQuestion = async () => {
    try {
      const response = await axios.get(`/api/question/${id}/${category}`);
      const { created_at, file_id, paragraph_id, paragraph_title, updated_at, attached_file, ...filteredData } = response.data.data; // Exclude unnecessary fields
      setQuestion(response.data.data);
      setEditData(filteredData); // Set the form data without the excluded fields
      if (attached_file) {
        setFile(attached_file); // Set the existing file for display
      }
    } catch (error) {
      console.error('Error fetching question details:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {

    fetchQuestion();
  }, [id, category]);

  // const handleEdit = async () => {
  //   try {
  //     await axios.post(`/api/question/edit/${id}/${category}`, { ...editData, file_name: fileName });
  //     fetchQuestion();
  //     setEditing(false);
  //     navigate(`/question/${id}/${category}`); // Redirect after edit
  //   } catch (error) {
  //     console.error('Error updating question:', error);
  //   }
  // };

  const handleEdit = async () => {
    const formData = new FormData();
    // Append the question data
    Object.keys(editData).forEach(key => {
      formData.append(key, editData[key]);
    });
    if (file) {
      formData.append('file', file); // Append the new file if it exists
    }
    
    try {
      await axios.post(`/api/question/edit/${id}/${category}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchQuestion();
      setEditing(false);
      navigate(`/question/${id}/${category}`);
    } catch (error) {
      console.error('Error updating question:', error);
    }
  };

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Set the new file
  };

  if (loading) {
    return <p>Loading question details...</p>;
  }

  if (!question) {
    return <p>Question not found.</p>;
  }

  const renderDetails = () => {
    switch (category) {
      case 'writing':
        return (
          <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            <p><strong>Word Count:</strong> 
              {editing ? (
                <input type="number" name="word_count" value={editData.word_count} onChange={handleChange} />
              ) : question.word_count}
            </p>
            {/* <p><strong>Correct Answer:</strong> 
              {editing ? (
                <input type="text" name="correct_answer" value={editData.correct_answer} onChange={handleChange} />
              ) : question.correct_answer}
            </p> */}
            <p><strong>Marks:</strong> 
              {editing ? (
                <input type="number" name="marks" value={editData.marks} onChange={handleChange} />
              ) : question.marks}
            </p>
            {/* <p><strong>Out of:</strong> 
              {editing ? (
                <input type="number" name="out_of" value={editData.out_of} onChange={handleChange} />
              ) : question.out_of}
            </p> */}
          </>
        );
      case 'speaking':
        return (
          <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            {/* <p><strong>Correct Answer:</strong> 
              {editing ? (
                <input type="text" name="correct_answer" value={editData.correct_answer} onChange={handleChange} />
              ) : question.correct_answer}
            </p> */}
            <p><strong>Has Time Limit:</strong> 
              {editing ? (
                <input type="checkbox" name="hasTimeLimit" checked={editData.hasTimeLimit} onChange={e => setEditData({ ...editData, hasTimeLimit: e.target.checked })} />
              ) : question.hasTimeLimit ? 'Yes' : 'No'}
            </p>
            <p><strong>Time Limit:</strong> 
              {editing ? (
                <input type="number" name="timeLimit" value={editData.timeLimit} onChange={handleChange} />
              ) : `${question.timeLimit} minutes`}
            </p>
            <p><strong>Marks:</strong> 
              {editing ? (
                <input type="number" name="marks" value={editData.marks} onChange={handleChange} />
              ) : question.marks}
            </p>
            {/* <p><strong>Out of:</strong> 
              {editing ? (
                <input type="number" name="out_of" value={editData.out_of} onChange={handleChange} />
              ) : question.out_of}
            </p> */}
          </>
        );
      case 'multiple_choice_questions':
        return (
          <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            <p><strong>Options:</strong> 
              {editing ? (
                <input type="text" name="options" value={editData.options.join(', ')} onChange={e => setEditData({ ...editData, options: e.target.value.split(', ') })} />
              ) : question.options.join(', ')}
            </p>
            <p><strong>{question.is_multi ? 'Multi Correct Answers' : 'Single Correct Answer'}:</strong> 
              {editing ? (
                <input type="text" name={question.is_multi ? 'multi_correct_answer' : 'single_correct_answer'} value={editData.is_multi ? editData.multi_correct_answer : editData.single_correct_answer} onChange={handleChange} />
              ) : question.is_multi ? question.multi_correct_answer : question.single_correct_answer}
            </p>
            <p><strong>Marks:</strong> 
              {editing ? (
                <input type="number" name="marks" value={editData.marks} onChange={handleChange} />
              ) : question.marks}
            </p>
            {/* <p><strong>Out of:</strong> 
              {editing ? (
                <input type="number" name="out_of" value={editData.out_of} onChange={handleChange} />
              ) : question.out_of}
            </p> */}
          </>
        );
      case 'question_answer':
        return (
          <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            {/* <p><strong>Correct Answer:</strong> 
              {editing ? (
                <input type="text" name="correct_answer" value={editData.correct_answer} onChange={handleChange} />
              ) : question.correct_answer}
            </p> */}
            <p><strong>Is Numeric:</strong> 
              {editing ? (
                <input type="checkbox" name="is_numeric" checked={editData.is_numeric} onChange={e => setEditData({ ...editData, is_numeric: e.target.checked })} />
              ) : question.is_numeric ? 'Yes' : 'No'}
            </p>
          </>
        );
      case 'fill_in_the_blank':
        return (
          <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            <p><strong>Options:</strong> 
              {editing ? (
                <input type="text" name="options" value={editData.options.join(', ')} onChange={e => setEditData({ ...editData, options: e.target.value.split(', ') })} />
              ) : question.options.join(', ')}
            </p>
            <p><strong>Correct Answer:</strong> 
              {editing ? (
                <input type="text" name="correct_answer" value={editData.correct_answer} onChange={handleChange} />
              ) : question.correct_answer}
            </p>
            <p><strong>Marks:</strong> 
              {editing ? (
                <input type="number" name="marks" value={editData.marks} onChange={handleChange} />
              ) : question.marks}
            </p>
            {/* <p><strong>Out of:</strong> 
              {editing ? (
                <input type="number" name="out_of" value={editData.out_of} onChange={handleChange} />
              ) : question.out_of}
            </p> */}
          </>
        );
      case 'listening':
        return (
          <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            {/* <p><strong>Correct Answer:</strong> 
              {editing ? (
                <input type="text" name="correct_answer" value={editData.correct_answer} onChange={handleChange} />
              ) : question.correct_answer}
            </p> */}
            <p><strong>Marks:</strong> 
              {editing ? (
                <input type="number" name="marks" value={editData.marks} onChange={handleChange} />
              ) : question.marks}
            </p>
            {/* <p><strong>Out of:</strong> 
              {editing ? (
                <input type="number" name="out_of" value={editData.out_of} onChange={handleChange} />
              ) : question.out_of}
            </p> */}
          </>
        );
      case 'reordering':
        return (
            <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            <p><strong>Correct Answer:</strong> 
              {editing ? (
                <input type="text" name="correct_answer" value={editData.correct_answer} onChange={handleChange} />
              ) : question.correct_answer}
            </p>
            <p><strong>Marks:</strong> 
              {editing ? (
                <input type="number" name="marks" value={editData.marks} onChange={handleChange} />
              ) : question.marks}
            </p>
            {/* <p><strong>Out of:</strong> 
              {editing ? (
                <input type="number" name="out_of" value={editData.out_of} onChange={handleChange} />
              ) : question.out_of}
            </p> */}
          </>
        );
      case 'highlighting':
        return (
          <>
            <p><strong>Question:</strong> 
              {editing ? (
                <input type="text" name="question" value={editData.question} onChange={handleChange} />
              ) : question.question}
            </p>
            <p><strong>Is Window Split:</strong> 
              {editing ? (
                <input type="checkbox" name="is_window_splited" checked={editData.is_window_splited} onChange={e => setEditData({ ...editData, is_window_splited: e.target.checked })} />
              ) : question.is_window_splited ? 'Yes' : 'No'}
            </p>
            <p><strong>Correct Answer:</strong> 
              {editing ? (
                <input type="text" name="correct_answer" value={editData.correct_answer} onChange={handleChange} />
              ) : question.correct_answer}
            </p>
            <p><strong>Marks:</strong> 
              {editing ? (
                <input type="number" name="marks" value={editData.marks} onChange={handleChange} />
              ) : question.marks}
            </p>
            {/* <p><strong>Out of:</strong> 
              {editing ? (
                <input type="number" name="out_of" value={editData.out_of} onChange={handleChange} />
              ) : question.out_of}
            </p> */}
          </>
        );
      default:
        return <p>Unknown question type.</p>;
    }
  };

  return (
    <PrivatePageLayout>
      <div>
        <h2>Question Details</h2>
        {renderDetails()}
        {/* <p><strong>Attached File:</strong> {fileName}</p> */}
        {file && (
          <div>
            <p><strong>Current File:</strong> {file.file_name}</p>
            <audio controls>
              <source src={"http://195.26.255.98:8002/storage/"+file.file_location} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
         {editing && (
          <div>
            <label>
              Upload New File: 
              <input type="file" onChange={handleFileChange} />
            </label>
          </div>
        )}
        {editing ? (
          <div>
            <button onClick={handleEdit}>Save</button>
            <button onClick={() => setEditing(false)}>Cancel</button>
          </div>
        ) : (
          <button onClick={() => setEditing(true)}>Edit</button>
        )}
      </div>
    </PrivatePageLayout>
  );
};

export default SingleQuestion;
