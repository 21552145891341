import React, { useState, useEffect } from 'react';
import PrivatePageLayout from '../../layouts/PrivatePageLayout';
import axios from "../../Axios";
import { Button, TextField, FormControl } from '@mui/material';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

export const ExamAddPage = () => {
    const [examName, setExamName] = useState('');
    const [examTitle, setExamTitle] = useState('');
    const [examDetails, setExamDetails] = useState('');
    const [examType, setExamType] = useState(null); // state for selected exam type
    const [examAmountBDT, setExamAmountBDT] = useState('');
    const [examAmountUSD, setExamAmountUSD] = useState('');
    const [examValidity, setExamValidity] = useState('');
    const [examTags, setExamTags] = useState('');
    const [examModules, setExamModules] = useState([]);

    const [moduleOptions, setModuleOptions] = useState([]);
    const [examTypeOptions, setExamTypeOptions] = useState([
        { value: 'freemium', label: 'Freemium' },
        { value: 'premium', label: 'Premium' }
    ]);
    
    const navigate = useNavigate();

    useEffect(() => {
        const fetchModules = async () => {
            try {
                const response = await axios.get('/api/modules'); // Adjust the API endpoint accordingly
                setModuleOptions(response.data.data.map(module => ({
                    value: module.id,
                    label: module.title,
                })));
            } catch (error) {
                console.error("Error fetching modules:", error);
            }
        };

        fetchModules();
    }, []);

    const handleSubmitExam = async () => {
        const data = {
            name: examName,
            title: examTitle,
            details: examDetails,
            type: examType?.value, // Get selected exam type value
            amount_bdt: examAmountBDT,
            amount_usd: examAmountUSD,
            validity: examValidity,
            module_ids: JSON.stringify(examModules.map(module => module.value)),
            tags: examTags
        };

        try {
            await axios.post('/api/exam/save', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            alert('Exam added successfully!');
            navigate('/dashboard/exam');
        } catch (error) {
            console.error('Error adding exam:', error);
            alert('Failed to add exam.');
        }
    };

    return (
        <PrivatePageLayout>
            <div style={{ padding: '20px' }}>
                <h2>Add a New Exam</h2>
                
                <TextField
                    margin="dense"
                    label="Exam Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={examName}
                    onChange={(e) => setExamName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Exam Title"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={examTitle}
                    onChange={(e) => setExamTitle(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Exam Details"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={examDetails}
                    onChange={(e) => setExamDetails(e.target.value)}
                />
                <FormControl fullWidth style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <h4>Select Exam Type</h4>
                    <Select
                        value={examType}
                        onChange={(selectedType) => setExamType(selectedType)}
                        options={examTypeOptions}
                    />
                </FormControl>
                <TextField
                    margin="dense"
                    label="Amount (BDT)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={examAmountBDT}
                    onChange={(e) => setExamAmountBDT(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Amount (USD)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={examAmountUSD}
                    onChange={(e) => setExamAmountUSD(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Validity (YYYY-MM-DD)"
                    type="date"
                    fullWidth
                    variant="outlined"
                    value={examValidity}
                    onChange={(e) => setExamValidity(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    margin="dense"
                    label="Tags (comma separated)"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={examTags}
                    onChange={(e) => setExamTags(e.target.value)}
                />
                <FormControl fullWidth style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <h4>Select Modules</h4>
                    <Select
                        isMulti
                        value={examModules}
                        onChange={(selectedOptions) => setExamModules(selectedOptions)}
                        options={moduleOptions}
                    />
                </FormControl>

                <div style={{ marginTop: '20px' }}>
                    <Button variant="contained" onClick={handleSubmitExam} color="secondary">
                        Add Exam
                    </Button>
                </div>
            </div>
        </PrivatePageLayout>
    );
};
