import {
    Avatar,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Login.css";
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";
//   import { Captcha } from "../Common/Captcha";
import axios from "../Axios";
import Cookies from "universal-cookie";
import { BEARER_TOKEN_COOKIE_NAME } from "../../utils/constants";
import useAuth from "../../hooks/authHooks";
import { useNavigate } from "react-router-dom";
import AppButton from "../Common/AppButton";
import useFetchProfilePicture from "../../hooks/useFetchProfilePicture";
import ForgotPasswordDialog from "../ForgotPassword/ForgotPasswordDialog";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const backgroundStyle = {
    backgroundImage: 'url("/images/login-bg.jpg")', // Adjust based on your folder structure
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
};

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [captchaHash, setCaptchaHash] = useState(null);
    const [inputCaptchaValue, setInputCaptchaValue] = useState("");
    const [captchaMatchError, setCaptchaMatchError] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isMatchingCaptcha, setIsMatchingCaptcha] = useState(false);
    const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const { loggedInUser, fetchAuthUser } = useAuth();
    // const { fetchProfilePicture } = useFetchProfilePicture();
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: "/" });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleCaptchaValue = (event) => {
        setInputCaptchaValue(event.target.value);
    };

    const handleFetchCaptcha = ({ captchaHash: hash }) => {
        setCaptchaHash(hash);
    };

    const handleCloseEmailDialog = () => {
        setIsEmailDialogOpen(false);
    };
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    const handleApi = () => {
        setIsLoggingIn(true);

        axios
            .post('/api/login', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response)
                const bearerToken = response?.data?.data?.token;

                if (!!bearerToken) {
                    cookies.set(BEARER_TOKEN_COOKIE_NAME, bearerToken);

                    // fetchAuthUser();
                    const userId = response?.data?.data?.id;
                    fetchAuthUser(userId)
                
                } 
                // if (response?.data?.success == true){
                //     const userId = response?.data?.data?.id;
                //     fetchAuthUser(userId)
                // }
                else {
                    throw Error("Can't login with provided credentials!");
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    alert(error.response.data.detail);
                } else if (error.request) {
                    // The request was made but no response was received
                    alert(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    alert("Error", error.detail);
                }
            })
            .finally(() => {
                setIsLoggingIn(false);
            });
    };

    // const captchaMatch = () => {
    //   setIsMatchingCaptcha(true);

    //   axios
    //     .post("/api/captcha/match", {
    //       hash: captchaHash,
    //       text: inputCaptchaValue,
    //     })
    //     .then((response) => {
    //       const isMatched = response.data.result;

    //       setCaptchaMatchError(!isMatched);
    //       handleApi();
    //     })
    //     .catch((error) => {
    //       setCaptchaMatchError(true);
    //     })
    //     .finally(() => {
    //       setIsMatchingCaptcha(false);
    //     });
    // };

    useFetchProfilePicture();

    useEffect(() => {
        if (!!loggedInUser && loggedInUser?.id) {
            navigate("/dashboard");
        }
    }, [loggedInUser, navigate]);

    return (
        <Grid className="login_up_dummy_div" style={backgroundStyle}>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Paper elevation={20} className="login_up_content">
                <Grid align="center" className="login-icon-div">
                    <Avatar className="avatar_style">
                        {/* <AddCircleOutlineOutlined /> */}
                    </Avatar>
                    <h2>Login</h2>
                </Grid>
                <form
                // onKeyDown={(event) => {
                //   if (event.code === "Enter") {
                //     captchaMatch();
                //   }
                // }}
                >
                    <div className="login-form">
                        <TextField
                            required={true}
                            fullWidth
                            label="Email / Mobile"
                            value={email}
                            onChange={handleEmail}
                            inputProps={{
                                style:
                                {
                                    height: "15px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontOpticalSizing: "auto",
                                    fontWeight: "400", /* Replace with your desired default weight */
                                    fontStyle: "normal"
                                }
                            }}
                            sx={{
                                '& label.Mui-focused': {
                                    color: 'var(--primary-orange)',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // borderColor: 'gray',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                },
                            }}
                        />
                        {/* <FormControl>
                <InputLabel htmlFor="login-password"
                
                >Password</InputLabel>
                <OutlinedInput
                  id="login-password"
                  required={true}
                  fullWidth
                  label="Password"
                  value={password}
                  onChange={handlePassword}
                  inputProps={{ style: { height: "15px" } }}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl> */}
                        <FormControl fullWidth variant="outlined" required={true}
                         sx={{
                                fontFamily: 'Rubik, sans-serif',
                                    fontOpticalSizing: 'auto',
                                    fontWeight: '400', // Adjust to your desired weight
                                    fontStyle: 'normal',
                                '& label.Mui-focused': {
                                    color: 'var(--primary-orange)',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // borderColor: 'gray',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                },
                            }}>
                            <InputLabel
                                htmlFor="login-password"
                                sx={{
                                    fontFamily: 'Rubik, sans-serif',
                                    fontOpticalSizing: 'auto',
                                    fontWeight: '400', // Adjust to your desired weight
                                    fontStyle: 'normal',
                                    '&.Mui-focused': {
                                        color: 'var(--primary-orange)',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // borderColor: 'gray',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                    },
                                }}
                            >
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="login-password"
                                label="Password"
                                value={password}
                                onChange={handlePassword}
                                type={showPassword ? 'text' : 'password'}
                                inputProps={{
                                    style: {
                                        height: '15px',
                                        fontFamily: 'Rubik, sans-serif',
                                        fontOpticalSizing: 'auto',
                                        fontWeight: '400', // Adjust to your desired weight
                                        fontStyle: 'normal',
                                    },
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                sx={{
                                    '& label.Mui-focused': {
                                        color: 'var(--primary-orange)',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // borderColor: 'gray',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                    },
                                }}
                            />
                        </FormControl>
                        {/* <Button variant='contained' className='text_field_login' */}
                        {/* // onClick={handleApi} */}
                        {/* >লগইন</Button> */}
                        {/* <Captcha onFetchCaptcha={handleFetchCaptcha} /> */}
                        {/* {captchaMatchError && (
                <p style={{ color: "red" }}>
                  Captcha did not match. Please try again.
                </p>
              )} */}
                        {/* <TextField
                required={true}
                fullWidth
                label="Enter Captcha Here"
                variant="outlined"
                value={inputCaptchaValue}
                onChange={handleCaptchaValue}
                inputProps={{ style: { height: "15px" } }}
              /> */}
                        <AppButton
                            variant="contained"
                            // onClick={captchaMatch}
                            // loading={isLoggingIn || isMatchingCaptcha}
                            onClick={handleApi}
                            style={{ background: 'var(--primary-orange)' }}
                        >
                            Login
                        </AppButton>
                        <Stack>
                            <Stack>
                                <Typography sx={{
                                    fontSize: "14px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontOpticalSizing: "auto",
                                    fontWeight: "400", /* Replace with your desired default weight */
                                    fontStyle: "normal"
                                }}>
                                    Forgot your password?
                                    <AppButton
                                        size="small"
                                        onClick={() => {
                                            setIsEmailDialogOpen(true);
                                        }}
                                        sx={{ minWidth: 0, color: 'var(--primary-orange)', fontWeight: 'bold' }}
                                    >
                                        <span style={{fontWeight: '700'}}>Click Here</span>
                                    </AppButton>
                                </Typography>
                            </Stack>
                            <Stack>
                                <Typography sx={{
                                    fontSize: "14px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontOpticalSizing: "auto",
                                    fontWeight: "400", /* Replace with your desired default weight */
                                    fontStyle: "normal"
                                }}>
                                    Don't have an account? Please{" "}
                                    <AppButton href="/signup" size="small" sx={{ minWidth: 0, color: 'var(--primary-orange)', fontWeight: 'bold' }}>
                                    <span style={{fontWeight: '700'}}>Register</span>
                                    </AppButton>
                                </Typography>
                            </Stack>
                        </Stack>
                    </div>
                </form>
            </Paper>
            <ForgotPasswordDialog
                open={isEmailDialogOpen}
                onClose={handleCloseEmailDialog}
            />
        </Grid>
    );
};

export default Login;
