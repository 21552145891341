import React from "react";
import { MdDashboard } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  DEFAULT_PARENT_CATEGORY,
  PARENT_BLOG_CATEGORIES,
} from "../../utils/constants";

const PRIVATE_ROUTES = [
  {
    href: "/dashboard",
    menuIcon: <MdDashboard color="var(--primary-orange)" />,
    title: "DashBoard",
  },
];

export const LeftSideBar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Stack
      sx={{
        width: "300px",
        borderRight: "1px solid #FF4A17",
        padding: "8px 20px 8px 0",
        fontSize: "16px",
        fontWeight: "500",
        a: {
          color: "black",
          transition: "color 0.2s ease",
          ":hover": {
            color: "var(--primary-orange)",
          },
        },
        svg: {
          fontSize: "24px",
        },
      }}
      spacing={1}
    >
      {PRIVATE_ROUTES.map((route) => (
        <Stack spacing={1} key={route.href}>
          <Link
            to={route.href}
            style={{
              textDecoration: "none",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                backgroundColor:
                  route.href === pathname
                    ? "rgba(0, 0, 0, 0.04)"
                    : "transparent",
                color:
                  route.href === pathname ? "primary.main" : "inherit",
                padding: "16px 8px",
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  color: "primary.main",
                },
              }}
            >
              {route.menuIcon}
              <Typography sx={{ color: 'var(--primary-orange)' }}>
                {route.title}
              </Typography>
            </Stack>
          </Link>
          {route.href === "/dashboard" && (
            <Box sx={{ marginLeft: "16px !important" }}>
              {Object.entries(PARENT_BLOG_CATEGORIES).map(
                ([parentCategoryKey, parentCategoryName]) =>
                  parentCategoryKey !== DEFAULT_PARENT_CATEGORY ? (
                    <ListItemButton
                      key={parentCategoryKey}
                      component={Link}
                      to={`/dashboard/${parentCategoryName}`} // Adjust the link path as needed
                      sx={{
                        padding: "8px 16px",
                        backgroundColor:
                          pathname === `/dashboard/${parentCategoryName}`
                            ? "rgba(0, 0, 0, 0.04)"
                            : "transparent",
                        ":hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            pathname === `/dashboard/${parentCategoryName}`
                              ? "var(--primary-orange)"
                              : "inherit",
                        }}
                      >
                        {parentCategoryKey}
                      </Typography>
                    </ListItemButton>
                  ) : null
              )}
            </Box>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
