import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "../../Axios";

const ParagraphList = () => {
  const [paragraphs, setParagraphs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const pageSize = 5; // Number of paragraphs per page
  const pageCount = Math.ceil(paragraphs.length / pageSize);
  const visibleParagraphs = paragraphs.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    const fetchParagraphs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/paragraphs`);
        setParagraphs(response.data.data);
        setCurrentPage(1); // Reset to page 1 whenever fetching new data
      } catch (error) {
        console.error("Error fetching paragraphs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchParagraphs();
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGoToPage = (event) => {
    const newPage = parseInt(event.target.value);
    if (newPage >= 1 && newPage <= pageCount) {
      setCurrentPage(newPage);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (pageCount <= 3) {
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      let start = currentPage - 1;
      let end = currentPage + 1;
      if (start < 1) {
        start = 1;
        end = start + 2;
      }
      if (end > pageCount) {
        end = pageCount;
        start = end - 2;
      }
      if (start > 1) {
        pageNumbers.push(1, '...');
      }
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      if (end < pageCount) {
        pageNumbers.push('...', pageCount);
      }
    }
    return pageNumbers;
  };

  return (
    <div>
      {loading ? (
        <p>Loading paragraphs...</p>
      ) : (
        <div>
          <ul>
            {visibleParagraphs.map((paragraph) => (
              <li key={paragraph.id}>
                <p
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => navigate(`/paragraph/${paragraph.id}`)} // Navigate to the details page
                >
                  <strong>Title:</strong> {paragraph.title}
                </p>
                <hr />
              </li>
            ))}
          </ul>
            <br />
            <br />
            <br />
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            {getPageNumbers().map((pageNumber, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(pageNumber)}
                disabled={pageNumber === '...'}
                className={pageNumber === currentPage ? 'active' : ''}
              >
                {pageNumber}
              </button>
            ))}
            <button onClick={handleNextPage} disabled={currentPage === pageCount}>
              Next
            </button>
            <div className="goto-page">
              <span>Go to page:</span>
              <input
                type="number"
                min="1"
                max={pageCount}
                onChange={handleGoToPage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ParagraphList;
