import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../../Axios';

export const ParagraphAdd = () => {
//   const [open, setOpen] = useState(false);
//   const [title, setTitle] = useState('');
//   const [question, setQuestion] = useState('');
//   const [file, setFile] = useState(null);

//   const handleAddParagraphClick = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     resetFields();
//   };

//   const resetFields = () => {
//     setTitle('');
//     setQuestion('');
//     setFile(null);
//   };

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleSubmit = async () => {
//     const data = {
//       title,
//       question,
//       file: file ? file.name : null,
//     };

//     if (file) {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);

//       reader.onloadend = async () => {
//         data.file = reader.result;

//         try {
//           await axios.post('/api/paragraphs/save', data, {
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });
//           alert('Paragraph added successfully!');
//           handleClose();
//         } catch (error) {
//           console.error('Error adding paragraph:', error);
//           alert('Failed to add paragraph.');
//         }
//       };

//       reader.onerror = (error) => {
//         console.error('Error reading file:', error);
//         alert('Failed to read the file.');
//       };
//     } else {
//       try {
//         await axios.post('/api/paragraphs/save', data, {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
//         alert('Paragraph added successfully!');
//         handleClose();
//         resetFields();
//       } catch (error) {
//         console.error('Error adding paragraph:', error);
//         alert('Failed to add paragraph.');
//       }
//     }
//   };

const navigate = useNavigate();

const handleAddParagraphClick = () => {
  navigate('/dashboard/add-paragraph'); // Navigate to the new page
};

  return (
//     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//       <Button variant="contained" onClick={handleAddParagraphClick}>
//         Add Paragraph
//       </Button>

//       <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
//         <DialogTitle>Add a New Paragraph</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             label="Title"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//           />

//           <TextField
//             margin="dense"
//             label="Question"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={question}
//             onChange={(e) => setQuestion(e.target.value)}
//           />

//           <input type="file" onChange={handleFileChange} />
//         </DialogContent>

//         <DialogActions>
//           <Button onClick={handleClose} color="secondary">
//             Cancel
//           </Button>
//           <Button onClick={handleSubmit} color="primary" variant="contained">
//             Add Paragraph
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
<Button variant="contained" onClick={handleAddParagraphClick}>
  Add Paragraph
</Button>
</div>
);
};
