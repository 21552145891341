import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "../../Axios";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, FormControl, InputLabel, Select as MuiSelect, MenuItem } from '@mui/material';
import PrivatePageLayout from '../../layouts/PrivatePageLayout';
import Select from 'react-select';  // react-select for question multi-select
import { useTheme } from '@mui/material/styles';  // MUI theme for screen size handling
import useMediaQuery from '@mui/material/useMediaQuery'; // Media query for responsive layout

const questionTypes = [
  { value: "multiple_choice_questions", label: "Multiple Choice Questions" },
  { value: "question_answer", label: "Question Answer" },
  { value: "fill_in_the_blank", label: "Fill In The Blank" },
  { value: "listening", label: "Listening" },
  { value: "reordering", label: "Reordering" },
  { value: "highlighting", label: "Highlighting" },
  { value: "writing", label: "Writing" },
  { value: "speaking", label: "Speaking" }
];

const ParagraphDetails = () => {
  const { id } = useParams(); // Extract paragraph ID from URL
  const [paragraph, setParagraph] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [questionType, setQuestionType] = useState('');
  const [questions, setQuestions] = useState([]); // Fetched questions based on type
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [fetchingQuestions, setFetchingQuestions] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // Media query for mobile responsiveness

  const fetchParagraphDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/paragraph/${id}`);
      setParagraph(response.data.data); // Store paragraph details
    } catch (error) {
      console.error("Error fetching paragraph details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParagraphDetails(); // Fetch paragraph details on initial render
  }, [id]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setQuestionType('');
    setSelectedQuestions([]);
  };

  const handleQuestionTypeChange = async (type) => {
    setQuestionType(type);
    setSelectedQuestions([]);
    setFetchingQuestions(true);

    try {
      const response = await axios.get(`/api/question-list?type=${type}`);
      setQuestions(response.data.data); // Store fetched questions
    } catch (error) {
      console.error('Error fetching questions:', error);
      alert('Failed to fetch questions.');
    } finally {
      setFetchingQuestions(false);
    }
  };

  const handleSubmitQuestion = async () => {
    const data = {
      paragraph_id: id,
      type: questionType,
      question_ids: selectedQuestions.map(q => q.value), // Map selected questions to their IDs
    };

    try {
      await axios.post('/api/paragraph/question-assign', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      alert('Questions assigned successfully!');
      handleCloseDialog();
      fetchParagraphDetails(); // Re-fetch paragraph details to update the table with the new question
    } catch (error) {
      console.error('Error adding questions:', error);
      alert('Failed to assign questions.');
    }
  };

  if (loading) {
    return <p>Loading paragraph details...</p>;
  }

  if (!paragraph) {
    return <p>Paragraph not found.</p>;
  }

  return (
    <PrivatePageLayout>
      <div>
        <h2>{paragraph.title}</h2>
        <p><strong>Question:</strong> {paragraph.question}</p>
        <hr />
        <br />
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid black', padding: '8px' }}>Question</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Word Count</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Correct Answer</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Marks</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Out Of</th>
    </tr>
  </thead>
  <tbody>
    {paragraph.questions.map((questionGroup, groupIndex) => (
      questionGroup.map((questionItem) => (
        <tr key={`${groupIndex}-${questionItem.id}`}>
          <td style={{ border: '1px solid black', padding: '8px' }}>{questionItem.question}</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>{questionItem.word_count}</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>{questionItem.correct_answer}</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>{questionItem.marks}</td>
          <td style={{ border: '1px solid black', padding: '8px' }}>{questionItem.out_of}</td>
        </tr>
      ))
    ))}
  </tbody>
</table>

        
        {/* Add Question Button */}
        <Button variant="contained" color="primary" onClick={handleOpenDialog} style={{ marginTop: '20px' }}>
          Add Question
        </Button>

        {/* Add Question Dialog */}
        <Dialog
          fullWidth
          maxWidth="md" // Sets maxWidth to 'md' (medium screen size)
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              width: '70%', // Sets the width of the dialog to 70% of the screen
              maxWidth: 'none', // Ensures maxWidth does not override the 70% width
              height: '600px'
            }
          }}
          fullScreen={fullScreen} // Fullscreen for small screens
        >
          <DialogTitle>Add Questions</DialogTitle>
          <DialogContent>
            <FormControl fullWidth style={{ marginTop: '10px', marginBottom: '10px' }}>
              <InputLabel>Select Question Type</InputLabel>
              <MuiSelect
                fullWidth
                value={questionType}
                onChange={(e) => handleQuestionTypeChange(e.target.value)}
                displayEmpty
              >
                {questionTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>

            {fetchingQuestions ? (
              <CircularProgress />
            ) : (
              <FormControl fullWidth style={{ marginTop: '10px', marginBottom: '10px' }}>
                
                <Select
                  isMulti
                  value={selectedQuestions}
                  onChange={(selectedOptions) => setSelectedQuestions(selectedOptions)}
                  options={questions.map((question) => ({
                    value: question.id,
                    label: question.question,
                  }))}
                />
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmitQuestion} color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </PrivatePageLayout>
  );
};

export default ParagraphDetails;
