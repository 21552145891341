import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet";
import {
  // BLOG_CATEGORIES,
  DEFAULT_PARENT_CATEGORY,
  PARENT_BLOG_CATEGORIES,
} from "../../utils/constants";
import PrivatePageLayout from "../layouts/PrivatePageLayout";

export const UserDashBoard = () => {
  const [selectedParentCategory, setSelectedParentCategory] = useState(
    DEFAULT_PARENT_CATEGORY
  );
  const muiTheme = useTheme();

  const handleParentCategory = (event, newParentCategory) => {
    if (!!newParentCategory) {
      setSelectedParentCategory(newParentCategory);
    }
  };

  const getBgColorForCategoryCard = (parentCategory) => {
    const defaultColors = {
      backgroundColor: "rgba(255, 74, 23, 0.02)",
      borderColor: "rgba(255, 74, 23, 0.4)",
      ":hover": {
        boxShadow: `0 0 0 2px ${muiTheme.palette.primary.main}`,
      },
    };

    const colors = {
      [PARENT_BLOG_CATEGORIES.INFO_BLOG_CONTENT]: defaultColors,
      [PARENT_BLOG_CATEGORIES.AFFILIATE_REVIEW_CONTENT]: {
        backgroundColor: "rgba(255, 152, 0, 0.08)",
        borderColor: "rgba(255, 152, 0, 0.4)",
        ":hover": {
          boxShadow: `0 0 0 2px ${muiTheme.palette.warning.main}`,
        },
      },
      [PARENT_BLOG_CATEGORIES.MORE_AI_TOOLS]: {
        backgroundColor: "rgba(3, 169, 244, 0.08)",
        borderColor: "rgba(3, 169, 244, 0.4)",
        ":hover": {
          boxShadow: `0 0 0 2px ${muiTheme.palette.info.main}`,
        },
      },
      [PARENT_BLOG_CATEGORIES.GPT_TOOLS]: {
        backgroundColor: "rgba(46, 125, 50, 0.08)",
        borderColor: "rgba(46, 125, 50, 0.4)",
        ":hover": {
          boxShadow: `0 0 0 2px ${muiTheme.palette.success.main}`,
        },
      },
    };

    return colors[parentCategory] || defaultColors;
  };

  return (
    <PrivatePageLayout>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Grid
        container={true}
        spacing={3}
        sx={{
          padding: "0 32px 60px",
          // maxWidth: "1240px",
          margin: "auto !important",
          width: '100%',
        }}
      >
      </Grid>
    </PrivatePageLayout>
  );
};
