import React, { useState, useEffect } from 'react';
import PrivatePageLayout from '../../layouts/PrivatePageLayout';
import axios from "../../Axios";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, TextField, FormControl, InputLabel } from '@mui/material';
import Select from 'react-select'; // Import react-select for multi-selection
import { useNavigate } from 'react-router-dom';

export const ModuleAddPage = () => {
    const [title, setTitle] = useState(''); // Module title
    const [paragraphOptions, setParagraphOptions] = useState([]); // Paragraph options for the select dropdown
    const [selectedParagraphs, setSelectedParagraphs] = useState([]); // Selected paragraphs
    const [questionTypes, setQuestionTypes] = useState([
        { value: "multiple_choice_questions", label: "Multiple Choice Questions" },
        { value: "question_answer", label: "Question Answer" },
        { value: "fill_in_the_blank", label: "Fill In The Blank" },
        { value: "listening", label: "Listening" },
        { value: "reordering", label: "Reordering" },
        { value: "highlighting", label: "Highlighting" },
        { value: "writing", label: "Writing" },
        { value: "speaking", label: "Speaking" }
    ]); // Question types

    const [selectedQuestionType, setSelectedQuestionType] = useState(null); // Selected question type
    const [questionOptions, setQuestionOptions] = useState([]); // Questions based on selected type
    const [selectedQuestions, setSelectedQuestions] = useState([]); // Selected questions
    const [questionList, setQuestionList] = useState({}); // Final question list by type

    const handleRemoveQuestion = (type, questionId) => {
        // Filter out the question with the given questionId
        const updatedQuestions = questionList[type].filter((q) => q.value !== questionId);

        // Update the question list for the respective type
        setQuestionList((prev) => ({
            ...prev,
            [type]: updatedQuestions,
        }));
    };


    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the list of paragraphs for the multi-select dropdown
        const fetchParagraphs = async () => {
            try {
                const response = await axios.get('/api/paragraphs'); // Assuming there's an endpoint to fetch paragraphs
                setParagraphOptions(response.data.data.map(paragraph => ({
                    value: paragraph.id,
                    label: paragraph.title,
                })));
            } catch (error) {
                console.error("Error fetching paragraphs:", error);
            }
        };

        fetchParagraphs();
    }, []);

    useEffect(() => {
        if (selectedQuestionType) {
            // Fetch questions based on selected type
            const fetchQuestions = async () => {
                try {
                    const response = await axios.get(`/api/question-list?type=${selectedQuestionType.value}`);
                    setQuestionOptions(response.data.data.map(question => ({
                        value: question.id,
                        label: question.question,
                    })));

                    // Set selected questions for the selected type (if any)
                    setSelectedQuestions(questionList[selectedQuestionType.value] || []);
                } catch (error) {
                    console.error("Error fetching questions:", error);
                }
            };
            fetchQuestions();
        }
    }, [selectedQuestionType]);

    const handleQuestionSelect = (selectedOptions) => {
        setSelectedQuestions(selectedOptions);
        setQuestionList({
            ...questionList,
            [selectedQuestionType.value]: selectedOptions // Store both value and label for each question type
        });
    };

    const handleSubmit = async () => {
        const data = {
            title,
            paragraph_ids: selectedParagraphs.map(p => p.value), // Map selected paragraphs to their IDs
            question_list: Object.keys(questionList).reduce((acc, type) => {
                acc[type] = questionList[type].map(question => question.value); // Extract question IDs for API call
                return acc;
            }, {})
        };

        try {
            await axios.post('/api/module/save', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            alert('Module added successfully!');
            navigate('/dashboard/module');
        } catch (error) {
            console.error('Error adding module:', error);
            alert('Failed to add module.');
        }
    };

    return (
        <PrivatePageLayout>
            <div style={{ padding: '20px' }}>
                <h2>Add a New Module</h2>

                <TextField
                    margin="dense"
                    label="Module Title"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />

                <FormControl fullWidth style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <h4>Select Paragraph</h4>
                    <Select
                        isMulti
                        value={selectedParagraphs}
                        onChange={(selectedOptions) => setSelectedParagraphs(selectedOptions)}
                        options={paragraphOptions}
                    />
                </FormControl>

                <FormControl fullWidth style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <h4>Select Question Type</h4>
                    <Select
                        value={selectedQuestionType}
                        onChange={(selectedType) => setSelectedQuestionType(selectedType)}
                        options={questionTypes}
                    />
                </FormControl>

                {selectedQuestionType && (
                    <FormControl fullWidth style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <h4>Select Questions ({selectedQuestionType.label})</h4>
                        <Select
                            isMulti
                            value={selectedQuestions}
                            onChange={handleQuestionSelect}
                            options={questionOptions}
                        />
                    </FormControl>
                )}

                <div style={{ marginTop: '20px' }}>
                    <Button variant="contained" onClick={handleSubmit} color="primary">
                        Add Module
                    </Button>
                </div>

                <div style={{ marginTop: '20px' }}>
                    {Object.keys(questionList).map((type) => (
                        <div key={type} style={{ marginBottom: '15px' }}>
                            <strong style={{ fontSize: '16px', color: '#333' }}>
                                {questionTypes.find((qt) => qt.value === type)?.label}:
                            </strong>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    padding: '10px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    backgroundColor: '#f9f9f9',
                                }}      
                            >
                                {questionList[type].map((question, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            padding: '5px 10px',
                                            margin: '5px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            borderRadius: '25px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {question.label}
                                        <span
                                            onClick={() => handleRemoveQuestion(type, question.value)} // Call function to remove the question
                                            style={{
                                                marginLeft: '8px',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                color: '#ff4d4d',
                                            }}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </PrivatePageLayout>
    );
};
