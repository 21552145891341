import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from "../../Axios";
import "./QuestionList.css";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const QuestionList = () => {
  const [selectedType, setSelectedType] = useState('writing'); // Default type
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal state
  const [questionToDelete, setQuestionToDelete] = useState(null); // Question selected for deletion

  const navigate = useNavigate(); // Initialize useNavigate hook

  const pageSize = 5; // Number of questions per page
  const pageCount = Math.ceil(questions.length / pageSize);
  const visibleQuestions = questions.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const types = [
    'multiple_choice_questions',
    'question_answer',
    'fill_in_the_blank',
    'listening',
    'reordering',
    'highlighting',
    'writing',
    'speaking',
  ];

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/question-list?type=${selectedType}`);
        setQuestions(response.data.data);
        setCurrentPage(1); // Reset to page 1 whenever the type changes
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [selectedType]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGoToPage = (event) => {
    const newPage = parseInt(event.target.value);
    if (newPage >= 1 && newPage <= pageCount) {
      setCurrentPage(newPage);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (pageCount <= 3) {
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      let start = currentPage - 1;
      let end = currentPage + 1;
      if (start < 1) {
        start = 1;
        end = start + 2;
      }
      if (end > pageCount) {
        end = pageCount;
        start = end - 2;
      }
      if (start > 1) {
        pageNumbers.push(1, '...');
      }
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      if (end < pageCount) {
        pageNumbers.push('...', pageCount);
      }
    }
    return pageNumbers;
  };

  const handleDeleteClick = (question) => {
    setQuestionToDelete(question);
    setShowDeleteModal(true); // Open the modal
  };

  const confirmDelete = async () => {
    if (questionToDelete) {
      try {
        await axios.get(`/api/question/delete/${questionToDelete.id}/${selectedType}`);
        setQuestions(questions.filter(q => q.id !== questionToDelete.id)); // Remove from list
        setShowDeleteModal(false); // Close the modal
        alert('Question Deleted Successfully!');
      } catch (error) {
        console.error('Error deleting question:', error);
      }
    }
  };

  const closeModal = () => {
    setShowDeleteModal(false); // Close the modal without deleting
    setQuestionToDelete(null);
  };

  const handleQuestionClick = (questionId) => {
    navigate(`/question/${questionId}/${selectedType}`); // Navigate to SingleQuestion component
  };

  const handleChange = (event, selectedType) => {
    setSelectedType(selectedType);
  };

  return (
    <div>
      {/* <div style={{ marginBottom: '20px' }}>
        {types.map((type) => (
          <button
            key={type}
            onClick={() => setSelectedType(type)}
            style={{
              marginRight: '10px',
              backgroundColor: selectedType === type ? 'blue' : 'gray',
              color: 'white',
            }}
          >
            {type.replace('_', ' ')}
          </button>
        ))}
      </div> */}
         <ToggleButtonGroup style={{ marginBottom: '20px', background: '#aaaaaa' }}
        //  color="primary"
         value={selectedType}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
         >
        {types.map((type) => (
          <ToggleButton
            // key={type}
            // onClick={() => setSelectedType(type)}
            // style={{
            //   marginRight: '10px',
            //   backgroundColor: selectedType === type ? 'blue' : 'gray',
            //   color: 'white',
            // }}
            value={type}
          >
            {type.replace('_', ' ')}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {loading ? (
        <p>Loading questions...</p>
      ) : (
        <div>
          <ul>
            {visibleQuestions.map((question) => (
              <li key={question.id}>
                <div
                  style={{display: 'flex', justifyContent: 'space-between', padding: '20px'}}
                  onClick={() => handleQuestionClick(question.id)} // Navigate to question details on click
                >
                  <p><strong>Question:</strong> {question.question}</p>
                  <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(question); }}>
                    Delete
                  </button>
                </div>
                <hr />
              </li>
            ))}
          </ul>
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            {getPageNumbers().map((pageNumber, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(pageNumber)}
                disabled={pageNumber === '...'}
                className={pageNumber === currentPage ? 'active' : ''}
              >
                {pageNumber}
              </button>
            ))}
            <button onClick={handleNextPage} disabled={currentPage === pageCount}>
              Next
            </button>
            <div className="goto-page">
              <span>Go to page:</span>
              <input
                type="number"
                min="1"
                max={pageCount}
                onChange={handleGoToPage}
              />
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to delete this question?</p>
            <div className="modal-buttons">
              <button onClick={confirmDelete}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionList;
