import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import axios from '../../Axios';
import { useNavigate } from 'react-router-dom';

export const QuestionAdd = () => {
    const navigate = useNavigate();

    const handleAddQuestionClick = () => {
        navigate('/dashboard/add-question'); // Navigate to the new page
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleAddQuestionClick} style={{
                background: 'var(--primary-orange)',
                fontSize: "14px",
                fontFamily: "Rubik, sans-serif",
                fontOpticalSizing: "auto",
                fontWeight: "400", /* Replace with your desired default weight */
                fontStyle: "normal"
            }}>
                Add Question
            </Button>
        </div>
    );
};
