import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import axios from '../../Axios';
import { useNavigate } from 'react-router-dom';

export const ModuleAdd = () => {
    const navigate = useNavigate();

    const handleAddModuleClick = () => {
      navigate('/dashboard/add-module'); // Navigate to the new page
    };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="contained" onClick={handleAddModuleClick}>
        Add Module
      </Button>
    </div>
  );
};
