import { useContext, useState } from "react";
import { AuthContext } from "../AuthProvider";
import axios from "../Components/Axios";
import Cookies from "universal-cookie";
import { BEARER_TOKEN_COOKIE_NAME } from "../utils/constants";

const useAuth = () => {
  const { loggedInUser, setLoggedInUser } = useContext(AuthContext);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const fetchAuthUser = (id, onSuccess = () => {}) => {
    const cookies = new Cookies(null, { path: "/" });
    const authToken = cookies.get(BEARER_TOKEN_COOKIE_NAME);

    if (!!authToken) {
      setIsAuthenticating(true);
      const formData = new FormData();
      formData.append('id', id);
      console.log(id, authToken)

      axios
      .get(`/api/profile`, {
        params: { id: id },
        headers: {
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then((response) => {
          console.log(response?.data)
          const user = response?.data?.data;

          if (!!user) {
            setLoggedInUser({
              id: user?.id,
              email: user?.email,
              mobile: user?.mobile,
              name: user?.name,
              image: user?.image,
              token: user?.token,
              profilePicture: null,
            });

            onSuccess(user);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsAuthenticating(false);
        });
    } else {
      setIsAuthenticating(false);
    }
  };

  return {
    loggedInUser,
    setLoggedInUser,
    fetchAuthUser,
    isAuthenticating,
  };
};

export default useAuth;
