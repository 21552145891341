import React, { useState, useEffect } from 'react';
import axios from '../../Axios';
import PrivatePageLayout from '../../layouts/PrivatePageLayout';
import { Box, Button, Card, CardContent, Typography, Pagination, PaginationItem } from '@mui/material';
import ExamRightSideBar from './ExamRightSideBar';

export const Exam = () => {
  const [exams, setExams] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 4;

  // Fetch exams from the API
  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await axios.get('/api/exams'); // Replace with your actual API endpoint
        if (response.data.success) {
          setExams(response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching exams:', error);
      }
    };

    fetchExams();
  }, []);

  // Calculate the number of pages
  const numPages = Math.ceil(exams.length / itemsPerPage);

  // Get the items to display on the current page
  const paginatedData = exams.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <PrivatePageLayout>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" sx={{ gap: 2, margin: '20px 0' }}>
        {/* Exam Cards */}
        <Box
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent="center"
          sx={{ flex: 1, maxWidth: 'calc(100% - 320px)' }} // Adjust to account for the sidebar width
        >
          {paginatedData.map((exam) => (
            <Card key={exam.id} sx={{ width: 250 }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h6" component="div">
                  {exam.name}  {/* examName */}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {exam.title} {/* moduleName */}
                </Typography>
                <Button variant="contained" sx={{ mt: 2 }}>
                  {exam.type} {/* buttonText */}
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
        <ExamRightSideBar />
      </Box>
      {/* Pagination */}
      <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Pagination
          count={numPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
          siblingCount={1}
          boundaryCount={1}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'var(--primary-orange)',
                  color: '#fff',
                },
                '&:hover': {
                  backgroundColor: 'var(--primary-orange)',
                  color: '#fff',
                },
              }}
            />
          )}
        />
      </Box>
    </PrivatePageLayout>
  );
};

export default Exam;
