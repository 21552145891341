import {
  Dialog,
  DialogActions,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import AppButton from "../Common/AppButton";
import { TbShieldCheck } from "react-icons/tb";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import axios from "../Axios";

const SignUpConfirmationDialog = (props) => {

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [userTrue, setUserTrue] = useState(false);

  const { open, onClose } = props;
  const appTheme = useTheme();
  const formData = new FormData();
  formData.append('email', email);
  formData.append('otp', otp);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleOtp = (e) => {
    setOtp(e.target.value);
  };

  const handleApi = () => {
    axios
      .post('/api/otp-verify', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        setResponseMessage(response.data.message);
        setUserTrue(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          alert(error.response.data.detail);
        } else if (error.request) {
          alert(error.request);
        } else {
          alert("Error", error.detail);
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack sx={{ alignItems: "center", padding: "32px" }}>
        <TbShieldCheck
          style={{ color: appTheme.palette.success.main, fontSize: "80px" }}
        />
        <Typography sx={{ marginTop: "30px", textAlign: "center" }}>
          {userTrue ? (
            <>
              <Typography variant="h6" color="success.main">
                {responseMessage}
              </Typography>
            </>
          ) : (
            <>
              An OTP has been sent to your email address. Please, insert the Email and OTP for completing the registration. <br />
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField id="filled-basic"
                  label="Email"
                  variant="filled"
                  required={true}
                  fullWidth
                  value={email}
                  onChange={handleEmail}
                  inputProps={{
                    style:
                    {
                      height: "15px",
                      fontFamily: "Rubik, sans-serif",
                      fontOpticalSizing: "auto",
                      fontWeight: "400", 
                      fontStyle: "normal"
                    }
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'var(--primary-orange)',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'var(--primary-orange)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'var(--primary-orange)',
                      },
                    },
                  }}
                />
                <TextField id="filled-basic"
                  label="OTP"
                  variant="filled"
                  required={true}
                  fullWidth
                  value={otp}
                  onChange={handleOtp}
                  inputProps={{
                    style:
                    {
                      height: "15px",
                      fontFamily: "Rubik, sans-serif",
                      fontOpticalSizing: "auto",
                      fontWeight: "400", 
                      fontStyle: "normal"
                    }
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'var(--primary-orange)',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'var(--primary-orange)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'var(--primary-orange)',
                      },
                    },
                  }}
                />
              </Box>
              <AppButton
                variant="contained"
                onClick={handleApi}
                style={{ background: 'var(--primary-orange)' }}
              >
                Submit
              </AppButton>
              {responseMessage && (
                <Typography variant="body2" color="error.main">
                  {responseMessage}
                </Typography>
              )}
              <br />
              <br />
              Thank you for joining us!
            </>
          )}
        </Typography>
      </Stack>
      <DialogActions>
        <AppButton onClick={onClose} style={{ color: 'var(--primary-orange)' }}>Cancel</AppButton>
        <AppButton variant="contained" href="/" style={{ background: 'var(--primary-orange)' }}>
          Go to Login
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

export default SignUpConfirmationDialog;
