import React, { useState } from 'react';
import AppButton from '../../Common/AppButton';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Input,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import axios from "../../Axios";
import PrivatePageLayout from '../../layouts/PrivatePageLayout';

export const QuestionAddPage = () => {
    // const [open, setOpen] = useState(false);
    const [type, setType] = useState('');
    const [question, setQuestion] = useState('');
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [marks, setMarks] = useState('');
    const [outOf, setOutOf] = useState('');
    const [wordCount, setWordCount] = useState('');
    const [file, setFile] = useState(null);
    const [isNumeric, setIsNumeric] = useState(false);
    const [isWindowSplited, setIsWindowSplited] = useState(false);
    const [hasTimeLimit, setHasTimeLimit] = useState(false);
    const [timeLimit, setTimeLimit] = useState(0);
    // const [firstPart, setFirstPart] = useState('');
    // const [secondPart, setSecondPart] = useState('');
    const [options, setOptions] = useState([]);
    const [isMulti, setIsMulti] = useState(1);
    const [singleCorrectAnswer, setSingleCorrectAnswer] = useState('');
    const [multiCorrectAnswer, setMultiCorrectAnswer] = useState('');
    const navigate = useNavigate();

    const questionTypes = [
        { value: "multiple_choice_questions", label: "Multiple Choice Questions" },
        { value: "question_answer", label: "Question Answer" },
        { value: "fill_in_the_blank", label: "Fill In The Blank" },
        { value: "listening", label: "Listening" },
        { value: "reordering", label: "Reordering" },
        { value: "highlighting", label: "Highlighting" },
        { value: "writing", label: "Writing" },
        { value: "speaking", label: "Speaking" }
    ];


    const resetFields = () => {
        setQuestion('');
        setCorrectAnswer('');
        setMarks('');
        setOutOf('');
        setWordCount('');
        setFile(null);
        setIsWindowSplited(false);
        setIsNumeric(false);
        setHasTimeLimit(false);
        setTimeLimit(0);
        // setFirstPart('');
        // setSecondPart('');
        setOptions([]);
        setIsMulti(1);
        setSingleCorrectAnswer('');
        setMultiCorrectAnswer('');
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
        resetFields(); // Clear previous inputs when changing type
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // const handleSubmit = async () => {
    //     const data = {
    //         type: type,
    //         data: {
    //             marks: parseInt(marks),
    //             out_of: parseInt(outOf),
    //             file: file ? file.name : null,  // Include the file name if present, else null
    //         },
    //     };

    //     // Handle type-specific logic
    //     switch (type) {
    //         case 'fill_in_the_blank':
    //             data.data.first_part = firstPart;
    //             data.data.second_part = secondPart;
    //             data.data.options = options;
    //             data.data.correct_answer = correctAnswer;
    //             break;

    //         case 'speaking':
    //             data.data.question = question;
    //             data.data.correct_answer = correctAnswer;
    //             data.data.hasTimeLimit = hasTimeLimit;
    //             data.data.timeLimit = hasTimeLimit ? parseInt(timeLimit) : 0;
    //             break;

    //         case 'highlighting':
    //             data.data.question = question;
    //             data.data.correct_answer = correctAnswer;
    //             data.data.is_window_splited = isWindowSplited;
    //             break;

    //         case 'reordering':
    //             data.data.question = question;
    //             data.data.correct_answer = correctAnswer;
    //             break;

    //         case 'writing':
    //             data.data.question = question;
    //             data.data.correct_answer = correctAnswer;
    //             data.data.word_count = wordCount
    //             break;

    //         case 'listening':
    //             data.data.question = question;
    //             data.data.correct_answer = correctAnswer;
    //             break;

    //         case 'question_answer':
    //             data.data.question = question;
    //             data.data.correct_answer = correctAnswer;
    //             data.data.is_numeric = false;
    //             break;

    //         case 'multiple_choice_questions':
    //             data.data.question = question;
    //             data.data.is_multi = isMulti;
    //             data.data.options = options;
    //             data.data.single_correct_answer = isMulti === 0 ? singleCorrectAnswer : null;
    //             data.data.multi_correct_answer = isMulti === 1 ? multiCorrectAnswer : null;
    //             break;

    //         default:
    //             break;
    //     }

    //     if (file) {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);

    //         reader.onloadend = async () => {
    //             data.data.file = reader.result;

    //             try {
    //                 await axios.post('/api/question/save', data, {
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                     },
    //                 });
    //                 alert('Question added successfully!');
    //                 // handleClose();
    //                 setType('');
    //                 resetFields();
    //                 navigate('/dashboard/question');
    //             } catch (error) {
    //                 console.error('Error adding question:', error);
    //                 alert('Failed to add question.');
    //             }
    //         };

    //         reader.onerror = (error) => {
    //             console.error('Error reading file:', error);
    //             alert('Failed to read the file.');
    //         };
    //     } else {
    //         try {
    //             await axios.post('/api/question/save', data, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //             });
                
    //             alert('Question added successfully!');
    //             // handleClose();
    //             setType('')
    //             resetFields();
    //             navigate('/dashboard/question');
    //         } catch (error) {
    //             console.error('Error adding question:', error);
    //             alert('Failed to add question.');
    //         }
    //     }
    // };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('type', type);
    
        // Append common fields to formData
        formData.append('marks', marks);
        formData.append('out_of', outOf);
    
        if (file) {
            formData.append('file', file); // Attach file if present
        }
    
        // Handle type-specific logic and append to formData
        switch (type) {
            case 'fill_in_the_blank':
                // formData.append('first_part', firstPart);
                // formData.append('second_part', secondPart);
                formData.append('question', question);
                formData.append('options', options.join(','));
                formData.append('correct_answer', correctAnswer);
                break;
    
            case 'speaking':
                formData.append('question', question);
                formData.append('correct_answer', correctAnswer);
                formData.append('hasTimeLimit', hasTimeLimit);
                if (hasTimeLimit) {
                    formData.append('timeLimit', timeLimit);
                }
                break;
    
            case 'highlighting':
                formData.append('question', question);
                formData.append('correct_answer', correctAnswer);
                formData.append('is_window_splited', isWindowSplited);
                break;
    
            case 'reordering':
                formData.append('question', question);
                formData.append('correct_answer', correctAnswer);
                break;
    
            case 'writing':
                formData.append('question', question);
                formData.append('correct_answer', correctAnswer);
                formData.append('word_count', wordCount);
                break;
    
            case 'listening':
                formData.append('question', question);
                formData.append('correct_answer', correctAnswer);
                break;
    
            case 'question_answer':
                formData.append('question', question);
                formData.append('correct_answer', correctAnswer);
                formData.append('is_numeric', isNumeric);
                break;
    
            case 'multiple_choice_questions':
                formData.append('question', question);
                formData.append('is_multi', isMulti);
                formData.append('options', options.join(','));
                if (isMulti === 0) {
                    formData.append('single_correct_answer', singleCorrectAnswer);
                } else {
                    formData.append('multi_correct_answer', multiCorrectAnswer);
                }
                break;
    
            default:
                break;
        }
    
        try {
            await axios.post('/api/question/save', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            alert('Question added successfully!');
            setType('');
            resetFields();
            navigate('/dashboard/question');
        } catch (error) {
            console.error('Error adding question:', error);
            alert('Failed to add question.');
        }
    }; 
    

    return (
        <PrivatePageLayout>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%', maxWidth: '600px' }}>
                <h2>Add a New Question</h2>
                <div>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Type</InputLabel>
    
                        <Select
                            value={type}
                            onChange={handleTypeChange}
                            label="Type"
                        >
                            {questionTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
    
                    {(type === 'writing' || type === 'reordering' || type === 'highlighting' || type === 'listening' || type === 'speaking' || type === 'question_answer') && (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Question"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                            />
    
    
                            <TextField
                                margin="dense"
                                label="Marks"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={marks}
                                onChange={(e) => setMarks(e.target.value)}
                            />
    
                            {/* <TextField
                                margin="dense"
                                label="Out Of"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={outOf}
                                onChange={(e) => setOutOf(e.target.value)}
                            /> */}
    
                            {type === 'writing' && (
                                <TextField
                                    margin="dense"
                                    label="Word Count"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    value={wordCount}
                                    onChange={(e) => setWordCount(e.target.value)}
                                />
                            )}
    
                            {type === 'speaking' && (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={hasTimeLimit}
                                                onChange={(e) => setHasTimeLimit(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Has Time Limit"
                                    />
                                    {hasTimeLimit && (
                                        <TextField
                                            margin="dense"
                                            label="Time Limit (in minutes)"
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            value={timeLimit}
                                            onChange={(e) => setTimeLimit(e.target.value)}
                                        />
                                    )}
                                </>
                            )}
    
                            {type === 'highlighting' && (
                                <>
                                  <TextField
                                  margin="dense"
                                  label="Correct Answer"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  value={correctAnswer}
                                  onChange={(e) => setCorrectAnswer(e.target.value)}
                              />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isWindowSplited}
                                            onChange={(e) => setIsWindowSplited(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Is Window Splited"
                                />
                                </>
                            )}

                            {type === 'reordering' && (
                                <>
                                  <TextField
                                  margin="dense"
                                  label="Correct Answers (comma separated)"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  value={correctAnswer}
                                  onChange={(e) => setCorrectAnswer(e.target.value)}
                              />
                                </>
                            )}
    
                            {type === 'question_answer' && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isNumeric}
                                            onChange={(e) => setIsNumeric(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Is Numeric"
                                />
                            )}
    
                            <FormControl fullWidth margin="dense">
                                <Input
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </FormControl>
                        </>
                    )}
    
                    {type === 'fill_in_the_blank' && (
                        <>
                           <TextField
                                autoFocus
                                margin="dense"
                                label="Question (write ##blank## for gap between sentence)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                            />
    
                            <TextField
                                margin="dense"
                                label="Options (comma separated)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={options.join(', ')}
                                onChange={(e) => setOptions(e.target.value.split(',').map(opt => opt.trim()))}
                            />
    
                            <TextField
                                margin="dense"
                                label="Correct Answer"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={correctAnswer}
                                onChange={(e) => setCorrectAnswer(e.target.value)}
                            />
    
                            <TextField
                                margin="dense"
                                label="Marks"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={marks}
                                onChange={(e) => setMarks(e.target.value)}
                            />
    
                            {/* <TextField
                                margin="dense"
                                label="Out Of"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={outOf}
                                onChange={(e) => setOutOf(e.target.value)}
                            /> */}
    
                            <FormControl fullWidth margin="dense">
                                <Input
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </FormControl>
                        </>
                    )}
    
                    {type === 'multiple_choice_questions' && (
                        <>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Question"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                            />
    
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isMulti === 1}
                                        onChange={(e) => setIsMulti(e.target.checked ? 1 : 0)}
                                        color="primary"
                                    />
                                }
                                label="Multiple Correct Answers"
                            />
    
                            <TextField
                                margin="dense"
                                label="Options (comma separated)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={options.join(', ')}
                                onChange={(e) => setOptions(e.target.value.split(',').map(opt => opt.trim()))}
                            />
    
                            {isMulti === 0 && (
                                <TextField
                                    margin="dense"
                                    label="Single Correct Answer"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={singleCorrectAnswer}
                                    onChange={(e) => setSingleCorrectAnswer(e.target.value)}
                                />
                            )}
    
                            {isMulti === 1 && (
                                <TextField
                                    margin="dense"
                                    label="Multiple Correct Answers (comma separated)"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={multiCorrectAnswer}
                                    onChange={(e) => setMultiCorrectAnswer(e.target.value)}
                                />
                            )}
    
                            <TextField
                                margin="dense"
                                label="Marks"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={marks}
                                onChange={(e) => setMarks(e.target.value)}
                            />
    
                            {/* <TextField
                                margin="dense"
                                label="Out Of"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={outOf}
                                onChange={(e) => setOutOf(e.target.value)}
                            />
     */}
                            <FormControl fullWidth margin="dense">
                                <Input
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </FormControl>
                        </>
                    )}
                </div>
    
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    {/* <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button> */}
                    <Button onClick={handleSubmit} color="primary" variant="contained">
                        Add Question
                    </Button>
                </div>
            </div>
        </div>
    </PrivatePageLayout>
    
    );
};
