import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import axios from '../../Axios';
import { useNavigate } from 'react-router-dom'; // For navigation after submission
import PrivatePageLayout from '../../layouts/PrivatePageLayout'

export const ParagraphAddPage = () => {
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [file, setFile] = useState(null);
  const navigate = useNavigate(); // For navigation

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('question', question);

    if (file) {
      formData.append('file', file);
    }

    try {
      await axios.post('/api/paragraphs/save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensures proper file upload format
        },
      });
      alert('Paragraph added successfully!');
      navigate('/dashboard/paragraph'); // Navigate back to the paragraph page after success
    } catch (error) {
      console.error('Error adding paragraph:', error);
      alert('Failed to add paragraph.');
    }
  };

  return (
    <PrivatePageLayout>
      <div style={{ padding: '20px' }}>
        <h2>Add a New Paragraph</h2>
        <TextField
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <TextField
          margin="dense"
          label="Question"
          type="text"
          fullWidth
          variant="outlined"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />

        <input type="file" onChange={handleFileChange} />

        <div style={{ marginTop: '20px' }}>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Add Paragraph
          </Button>
        </div>
      </div>
    </PrivatePageLayout>
  );
};
