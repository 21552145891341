import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "../../Axios";

const ModuleList = () => {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState(null);

  const pageSize = 5; // Number of modules per page
  const pageCount = Math.ceil(modules.length / pageSize);
  const visibleModules = modules.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    const fetchModules = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/modules'); // Fetch the modules
        setModules(response.data.data); // Set the modules from API response
        setCurrentPage(1); // Reset to page 1 whenever fetching new data
      } catch (error) {
        console.error("Error fetching modules:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchModules();
  }, []);

  const handleDeleteClick = (module) => {
    setModuleToDelete(module);
    setShowDeleteModal(true); // Open the modal
  };

  const confirmDelete = async () => {
    if (moduleToDelete) {
      try {
        await axios.get(`/api/module/delete/${moduleToDelete.id}`);
        setModules(modules.filter(q => q.id !== moduleToDelete.id)); // Remove from list
        setShowDeleteModal(false); // Close the modal
        alert('Module Deleted Successfully!');
      } catch (error) {
        console.error('Error deleting module:', error);
      }
    }
  };

  const closeModal = () => {
    setShowDeleteModal(false); // Close the modal without deleting
    setModuleToDelete(null);
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGoToPage = (event) => {
    const newPage = parseInt(event.target.value);
    if (newPage >= 1 && newPage <= pageCount) {
      setCurrentPage(newPage);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (pageCount <= 3) {
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      let start = currentPage - 1;
      let end = currentPage + 1;
      if (start < 1) {
        start = 1;
        end = start + 2;
      }
      if (end > pageCount) {
        end = pageCount;
        start = end - 2;
      }
      if (start > 1) {
        pageNumbers.push(1, '...');
      }
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
      if (end < pageCount) {
        pageNumbers.push('...', pageCount);
      }
    }
    return pageNumbers;
  };

  return (
    <div>
      {loading ? (
        <p>Loading modules...</p>
      ) : (
        <div>
          <ul>
            {visibleModules.map((module) => (
              <li key={module.id}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: '20px'}}>
                <p
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => navigate(`/module/${module.id}`)} // Navigate to the details page
                >
                  <strong>Title:</strong> {module.title}
                </p>
                <button onClick={(e) => { e.stopPropagation(); handleDeleteClick(module); }}>
                    Delete
                  </button>
                  </div>
                <hr />
              </li>
            ))}
          </ul>
          <br />
          <br />
          <br />
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            {getPageNumbers().map((pageNumber, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(pageNumber)}
                disabled={pageNumber === '...'}
                className={pageNumber === currentPage ? 'active' : ''}
              >
                {pageNumber}
              </button>
            ))}
            <button onClick={handleNextPage} disabled={currentPage === pageCount}>
              Next
            </button>
            <div className="goto-page">
              <span>Go to page:</span>
              <input
                type="number"
                min="1"
                max={pageCount}
                onChange={handleGoToPage}
              />
            </div>
          </div>
        </div>
      )}
       {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to delete this question?</p>
            <div className="modal-buttons">
              <button onClick={confirmDelete}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModuleList;
