import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import PrivatePageLayout from '../../layouts/PrivatePageLayout'
import { useNavigate } from 'react-router-dom';

export const AddExam = () => {
    const navigate = useNavigate();

    const handleAddExamClick = () => {
      navigate('/dashboard/add-exam'); // Navigate to the new page
    };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="contained" onClick={handleAddExamClick}>
        Add Exam
      </Button>
    </div>
  )
}
