import React from 'react'
// import { ModuleAdd } from './ModuleAdd'
import PrivatePageLayout from '../../layouts/PrivatePageLayout'
import { Grid } from '@mui/material'
// import ModuleList from './ModuleList'
import { AddExam } from './AddExam'

export const ExamAdd = () => {
  return (
    <PrivatePageLayout>
    {/* <Helmet>
      <title>Dashboard</title>
    </Helmet> */}
    <Grid
      container={true}
      spacing={3}
      sx={{
        padding: "20px 32px 60px",
        maxWidth: "1240px",
        margin: "auto !important",
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Grid 
      container={true}
      spacing={3}
      sx={{
        padding: "0 32px 60px",
        maxWidth: "1240px",
        margin: "auto !important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        justifyItems: 'flex-end',
        
      }}
      >
      <AddExam />
      </Grid>
      {/* <ModuleList /> */}
    </Grid>

  </PrivatePageLayout>
  )
}
