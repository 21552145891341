import { Avatar, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./SignUp.css";
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";
import { useNavigate, Link } from "react-router-dom";
import axios from "../Axios";
import { Captcha } from "../Common/Captcha";
import useAuth from "../../hooks/authHooks";
import AppButton from "../Common/AppButton";
import SignUpConfirmationDialog from "../SignUp/SignUpConfirmationDialog";
import { BiFontFamily } from "react-icons/bi";
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox'
import { Card, CardContent, Button } from '@mui/material';


const backgroundStyle = {
    backgroundImage: 'url("/images/signup-bg.jpg")', // Adjust based on your folder structure
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
};

const SignUp = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState("");
    const [userName, setUserName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [inputCaptchaValue, setInputCaptchaValue] = useState("");
    const [captchaHash, setCaptchaHash] = useState(null);
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [captchaMatchError, setCaptchaMatchError] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [showSignupSuccessDialog, setShowSignupSuccessDialog] = useState(false);
    const { loggedInUser } = useAuth();
    //   const [checkBoxMatchError, setCheckBoxMatchError] = useState(true)
    const [showCheckBoxCard, setShowCheckBoxCard] = useState(false);


    const [state, setState] = React.useState({
        checkBox: false,
    });

    const { checkBox } = state;

    const handleCheckBox = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        // setCheckBoxMatchError(!checkBoxMatchError)
        handleCloseCard()
    };


    const handleFullName = (event) => {
        setFullName(event.target.value);
    };

    const handleUserName = (event) => {
        setUserName(event.target.value);
    };

    const handleMobile = (event) => {
        setMobile(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    };

    //   const handleCaptchaValue = (event) => {
    //     setInputCaptchaValue(event.target.value);
    //   };

    //   const handleFetchCaptcha = ({ captchaHash: hash }) => {
    //     setCaptchaHash(hash);
    //   };

    const formData = new FormData();
    formData.append('name', fullName);
    formData.append('mobile', mobile);
    formData.append('email', email);
    formData.append('password', password);

    const handleApi = () => {
        if (!checkBox) { setShowCheckBoxCard(true) }
        if (!passwordMatchError && checkBox) {
            axios
                // .post("/api/register", {
                //   name: fullName,
                // //   username: userName,
                //   mobile,
                //   email,
                //   password,
                // //   text: inputCaptchaValue,
                // //   hash: captchaHash,
                // })
                .post('/api/register', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                // .post(`/api/register?name=${fullName}&mobile=${mobile}&email=${email}&password=${password}`)
                .then((response) => {
                    if (response.status === 200) {
                        setShowSignupSuccessDialog(true);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        alert(error.response.data.detail);
                    } else if (error.request) {
                        // The request was made but no response was received
                        alert(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        alert("Error", error.detail);
                    }
                })
                .finally(() => {
                    setIsSigningUp(false);
                });
        }
    };

    const handleCloseCard = () => {
        setShowCheckBoxCard(false);
    };

    //   const captchaMatch = () => {
    //     setIsSigningUp(true);

    //     axios
    //       .post("/api/captcha/match", {
    //         hash: captchaHash,
    //         text: inputCaptchaValue,
    //       })
    //       .then((response) => {
    //         const isMatched = response.data.result;

    //         setCaptchaMatchError(!isMatched);
    //         handleApi();
    //       })
    //       .catch((error) => {
    //         setCaptchaMatchError(true);
    //         setIsSigningUp(false);
    //       });
    //   };

    useEffect(() => {
        if (password === confirmPassword) {
            setPasswordMatchError(false);
        } else {
            setPasswordMatchError(true);
        }
    }, [password, confirmPassword]);

    useEffect(() => {
        if (!!loggedInUser && loggedInUser?.id) {
            navigate("/");
        }
    }, [loggedInUser, navigate]);

    return (
        <Grid className="sign_up_dummy_div" style={backgroundStyle}>
            <Helmet>
                <title>Sign Up</title>
            </Helmet>
            <Paper elevation={20} className="sign_up_content">
                <Grid align="center" className="login-icon-div">
                    <Avatar className="avatar_style">
                        {/* <AddCircleOutlineOutlined /> */}
                    </Avatar>
                    <h2 style={{ padding: "10px" }}>Sign Up</h2>
                </Grid>
                <form>
                    <div className="login-form">
                        <TextField
                            required={true}
                            fullWidth
                            label="Full Name"
                            variant="outlined"
                            className="text_field"
                            value={fullName}
                            onChange={handleFullName}
                            inputProps={{
                                style:
                                {
                                    height: "15px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontOpticalSizing: "auto",
                                    fontWeight: "400", /* Replace with your desired default weight */
                                    fontStyle: "normal"
                                }
                            }}
                            sx={{
                                '& label.Mui-focused': {
                                    color: 'var(--primary-orange)',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // borderColor: 'gray',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                },
                            }}
                        />
                        <TextField
                            required={true}
                            fullWidth
                            label="Mobile"
                            variant="outlined"
                            className="text_field"
                            value={mobile}
                            onChange={handleMobile}
                            inputProps={{
                                style: {
                                    height: "15px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontOpticalSizing: "auto",
                                    fontWeight: "400", /* Replace with your desired default weight */
                                    fontStyle: "normal"
                                }
                            }}
                            sx={{
                                '& label.Mui-focused': {
                                    color: 'var(--primary-orange)',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // borderColor: 'gray',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                },
                            }}
                        />
                        <TextField
                            required={true}
                            fullWidth
                            label="Email"
                            type="email"
                            variant="outlined"
                            className="text_field"
                            value={email}
                            onChange={handleEmail}
                            inputProps={{
                                style: {
                                    height: "15px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontOpticalSizing: "auto",
                                    fontWeight: "400", /* Replace with your desired default weight */
                                    fontStyle: "normal"
                                }
                            }}
                            sx={{
                                '& label.Mui-focused': {
                                    color: 'var(--primary-orange)',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // borderColor: 'gray',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'var(--primary-orange)',
                                    },
                                },
                            }}
                        />
                        <div className="signup-password">
                            <TextField
                                required={true}
                                fullWidth
                                type="password"
                                label="Password"
                                variant="outlined"
                                className="text_field"
                                value={password}
                                onChange={handlePassword}
                                inputProps={{
                                    style: {
                                        height: "15px",
                                        fontFamily: "Rubik, sans-serif",
                                        fontOpticalSizing: "auto",
                                        fontWeight: "400", /* Replace with your desired default weight */
                                        fontStyle: "normal"
                                    }
                                }}
                                sx={{
                                    '& label.Mui-focused': {
                                        color: 'var(--primary-orange)',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // borderColor: 'gray',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                required={true}
                                fullWidth
                                type="password"
                                label="Confirm password"
                                variant="outlined"
                                className="text_field"
                                value={confirmPassword}
                                onChange={handleConfirmPassword}
                                inputProps={{
                                    style: {
                                        height: "15px",
                                        fontFamily: "Rubik, sans-serif",
                                        fontOpticalSizing: "auto",
                                        fontWeight: "400", /* Replace with your desired default weight */
                                        fontStyle: "normal"
                                    }
                                }}
                                sx={{
                                    '& label.Mui-focused': {
                                        color: 'var(--primary-orange)',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // borderColor: 'gray',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'var(--primary-orange)',
                                        },
                                    },
                                }}
                            />
                        </div>
                        {passwordMatchError && (
                            <p style={{ color: "red" }}>
                                Passwords do not match.
                            </p>
                        )}
                        {/* <Captcha onFetchCaptcha={handleFetchCaptcha} /> */}
                        {/* {captchaMatchError && (
              <p style={{ color: "red" }}>
                Captcha did not match. Please try again.
              </p>
            )} */}
                        {/* <TextField
              required={true}
              fullWidth
              label="Enter Captcha Here"
              variant="outlined"
              className="text_field"
              value={inputCaptchaValue}
              onChange={handleCaptchaValue}
              inputProps={{ style: { height: "15px" } }}
            /> */}
                        {/* <CheckBox />*/}
                        <Box sx={{ display: 'flex' }}>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={checkBox} onChange={handleCheckBox} name="checkBox"
                                                sx={{
                                                    color: 'var(--primary-orange)',
                                                    '&.Mui-checked': {
                                                        color: 'var(--primary-orange)',
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Rubik, sans-serif',
                                                    fontWeight: 400, // Adjust this as needed
                                                    fontSize: '14px', // Adjust the font size as needed
                                                }}
                                            >
                                                I accept the{' '}
                                                <Link
                                                    href="/terms-and-conditions" // Replace with your actual terms and conditions link
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        color: 'var(--primary-orange)',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    terms and conditions
                                                </Link>
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                            {/* {checkBoxMatchError && (
              <p style={{ color: "red" }}>
                Please, accept the terms and conditions
              </p>
            )} */}
                        </Box>
                        {showCheckBoxCard && (
                            <Card sx={{
                                marginTop: -2
                            }}>
                                <CardContent>
                                    <Typography component="div" sx={{
                                        fontSize: '15px',
                                        color: 'var(--primary-orange)',
                                        fontFamily: "Rubik, sans-serif",
                                        fontOpticalSizing: "auto",
                                        fontWeight: "600", /* Replace with your desired default weight */
                                        fontStyle: "normal"
                                    }}>
                                        Please accept the terms and conditions
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{
                                        fontSize: '14px',
                                        marginTop: '5px',
                                        fontFamily: "Rubik, sans-serif",
                                        fontOpticalSizing: "auto",
                                        fontWeight: "400", /* Replace with your desired default weight */
                                        fontStyle: "normal"
                                    }}>
                                        You must accept the terms and conditions before proceeding.
                                    </Typography>
                                    {/* <Button onClick={handleCloseCard} sx={{ marginTop: 2 }} variant="contained" color="primary">
              Accept and Continue
            </Button> */}
                                </CardContent>
                            </Card>
                        )}
                        <AppButton
                            variant="contained"
                            //   onClick={captchaMatch}
                            onClick={handleApi}
                            loading={isSigningUp}
                            style={{ background: 'var(--primary-orange)' }}
                        //   disabled={captchaMatchError || passwordMatchError}
                        >
                            Submit
                        </AppButton>
                        <Stack>
                            <Typography sx={{
                                fontSize: "14px",
                                fontFamily: "Rubik, sans-serif",
                                fontOpticalSizing: "auto",
                                fontWeight: "400", /* Replace with your desired default weight */
                                fontStyle: "normal"
                            }}>
                                Already have an account? Please{" "}
                                <AppButton href="/" size="small" sx={{ minWidth: 0, color: 'var(--primary-orange)' }}>
                                    <span style={{ fontWeight: '700' }}>Login</span>
                                </AppButton>
                            </Typography>
                        </Stack>
                    </div>
                </form>
            </Paper>
            <SignUpConfirmationDialog
                open={showSignupSuccessDialog}
                onClose={() => setShowSignupSuccessDialog(false)}
            />
        </Grid>
    );
};

export default SignUp;
