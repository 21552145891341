import React, { useEffect } from "react";
import "./Home.css";
import { Route, Routes } from "react-router-dom";
import { Header } from "../Common/Header";
import Login from "../Pages/Login";
import SignUp from "../Pages/SignUp";
import { UserDashBoard } from "../Profile/UserDashBoard";
import useAuth from "../../hooks/authHooks";
import { CircularProgress, Stack, Typography } from "@mui/material";
import ConfirmEmail from "../Pages/Auth/ConfirmEmail";
import useFetchProfilePicture from "../../hooks/useFetchProfilePicture";
import HeaderLayout from "../layouts/HeaderLayout";
import { Question } from "../Pages/Question/Question";
import { Paragraph } from "../Pages/Paragraph/Paragraph";
import { Exam } from "../Pages/Exam/Exam";
import { Module } from "../Pages/Module/Module";
import { ParagraphAddPage } from "../Pages/Paragraph/ParagraphAddPage";
import ParagraphDetails from "../Pages/Paragraph/ParagraphDetails";
import { ModuleAddPage } from "../Pages/Module/ModuleAddPage";
import { QuestionAdd } from "../Pages/Question/QuestionAdd";
import { QuestionAddPage } from "../Pages/Question/QuestionAddPage";
import Profile from "../Pages/users/profile";
import SingleQuestion from "../Pages/Question/SingleQuestion";
import { AddExam } from "../Pages/Exam/AddExam";
import { ExamAdd } from "../Pages/Exam/ExamAdd";
import { ExamAddPage } from "../Pages/Exam/ExamAddPage";

export const Home = () => {
  const { fetchAuthUser, isAuthenticating } = useAuth();
  // const { fetchProfilePicture } = useFetchProfilePicture();

  useFetchProfilePicture();

  useEffect(() => {
    fetchAuthUser();
  }, []);

  return isAuthenticating ? (
    <Stack
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        zIndex: 100000,
      }}
    >
      <CircularProgress sx={{ color: "var(--primary-orange)" }} size={80} />
      <Typography sx={{ marginTop: "40px", fontSize: "24px" }}>
        Please, wait for a while!
      </Typography>
      <Typography sx={{ marginTop: "16px", fontSize: "18px" }}>
        Loading data...
      </Typography>
    </Stack>
  ) : (
    <>

      <div className="body-position">
        {/* <OfferCarousel /> */}
        <div className="body-padding">
          <Routes>
            {/* <Route exact path="/" element={<Homepage />} /> */}
            <Route exact path="/" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            {/* <div className="header-position"> */}
            {/* <Header /> */}
            <Route element={<HeaderLayout />}>
              <Route
                exact
                path="/confirm-mail/:hash"
                element={<ConfirmEmail />}
              />
              <Route exact path="/dashboard" element={<UserDashBoard />} />
              <Route exact path="/dashboard/question" element={<Question />} />
              <Route exact path="/dashboard/paragraph" element={<Paragraph />} />
              <Route exact path="/dashboard/exam" element={<Exam />} />
              <Route exact path="/dashboard/add exam" element={<ExamAdd />} />
              <Route exact path="/dashboard/add-exam" element={<ExamAddPage />} />
              <Route exact path="/dashboard/module" element={<Module />} />
              <Route path="/dashboard/add-question" element={<QuestionAddPage />} />
              <Route path="/dashboard/add-paragraph" element={<ParagraphAddPage />} />
              <Route path="/paragraph/:id" element={<ParagraphDetails />} />
              <Route path="/dashboard/add-module" element={<ModuleAddPage />} />
              <Route path="profile" element={<Profile />} />
              <Route path="/question/:id/:category" element={<SingleQuestion />} />
            </Route>
          </Routes>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
};
