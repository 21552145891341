import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
 

const ExamRightSideBar = () => {
    const [date, setDate] = useState(new Date());

    // Sample data for notices
    const noticeData = [
        { id: 1, title: 'Exam Schedule Released', date: '02-Sep-2024' },
        { id: 2, title: 'Results for Module 1 Published', date: '01-Sep-2024' },
        { id: 3, title: 'Holiday on 5th September', date: '31-Aug-2024' },
    ];

    const headerHeight = 80; // Adjust this value based on the height of your header
    const calendarHeight = 300; // Fixed height for the calendar

    return (
        <Box
            sx={{
                position: 'fixed', // Keep it fixed on the right
                right: 0,
                top: `${headerHeight}px`, // Start the sidebar below the header
                bottom: 0,
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                height: `calc(100vh - ${headerHeight}px)`, // Subtract header height from full viewport
                backgroundColor: '#fff', // Background color for better visibility
                zIndex: 1000, // Ensure it stays on top of other content
                padding: 0,
                margin: 0,
            }}
        >
            {/* Calendar - Fixed height */}
            <Box
                sx={{
                    height: `${calendarHeight}px`, // Set fixed height for the calendar
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    p: 2,
                    overflow: 'hidden', // Ensure no overflow issues
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'var(--primary-orange)',
                        fontWeight: '700',
                        mb: 2, // Margin bottom to separate title from calendar
                    }}
                >
                    Calendar
                </Typography>
                <Box
                    sx={{
                        height: '100%', // Make the calendar container take full height
                        transform: 'scale(0.7)', // Adjust the scale
                        transformOrigin: 'top',
                        // overflow: 'hidden', // Ensure no overflow issues
                    }}
                >
                    <Calendar onChange={setDate} value={date} />
                </Box>
            </Box>

            {/* Notice Board - Takes remaining height */}
            <Box
                sx={{
                    flex: '1', // Allow the notice board to take remaining space
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    p: 2,
                    overflowY: 'auto', // Scroll if notices overflow
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'var(--primary-orange)',
                        fontWeight: '700',
                    }}
                >
                    Notice Board
                </Typography>
                <List>
                    {noticeData.slice(0, 2).map((notice) => (
                        <ListItem key={notice.id}>
                            <ListItemText
                                primary={notice.title}
                                secondary={`Date: ${notice.date}`}
                            />
                        </ListItem>
                    ))}
                </List>
                {noticeData.length > 2 && (
                    <Typography
                        onClick={() => window.location.href = '/notices'} // Change to your route
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2, // Margin top to separate text from list
                            cursor: 'pointer',
                            color: 'var(--primary-orange)',
                            justifyContent: 'flex-end', // Align to the end of the container
                            textAlign: 'right', // Ensure text is aligned to the right
                        }}
                    >
                        See More
                        <KeyboardDoubleArrowRightIcon />
                    </Typography>
                )}

            </Box>
        </Box>
    );
};

export default ExamRightSideBar;
